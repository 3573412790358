import React from "react"
import "bulma/css/bulma.css"
import "../styles/dermatologia-online.scss"
import _navigation from "../components/_navigation"
import Footer from "../components/footer"
import bgderma from "../assets/doctor-online2-min.jpg"

const KonsultacjaDermatologicznaOnline = () => {
  const bg = bgderma
  const perks = ["Odpowiedź w 1 dzień roboczy", "e-Recepta", "Konsultacja z Lekarzem Dermatologiem", "Czas trwania ok. 15 min"]
  return (
    <div>
      <_navigation/>
      <section className="hero hero-promocje-background">
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title promocje-tytul">
              Konsultacja Dermatologiczna Online
            </h1>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-derma" style={{ backgroundImage: `url(${bg})` }}>
        </div>
      </section>
      <section className="container">
        <div className="info-derma">
          Porada dermatologiczna online przebiega podobnie jak zwykła wizyta u dermatologa z tą różnicą, że odbywa się w
          pełni przez internet. Nie jest to konsultacja za pośrednictwem video-live chatu czy innej wizualnej
          formy komunikacji bezpośredniej lecz rodzaj wywiadu medycznego w formie ankiety. <br/><br/>Całość odbywa sie w 3
          krokach, w trakcie
          których zostaniecie Państwo poproszeni o podanie swoich danych osobowych i wypełnienie formularza
          informacyjnego w
          formie wywiadu lekarskiego z możliwością załączenia zdjęć ukazujących problem. Ostatni krok to płatność po
          zakończeniu której Państwa informacje zostaną do nas przesłane, a nasz lekarz dermatolog zapozna się z nimi i
          w ciągu maksymalnie 1 dnia roboczego odpowie drogą mailową, przesyłając zalecenia i jeżeli sytuacja będzie
          tego wymagać to również e-receptę. <br/><br/>Porada przeprowadzana jest za pośrednictwem naszego portalu <a href="https://online-dermatolog.pl">www.online-dermatolog.pl</a> na który zostaniecie Państwo automatycznie przeniesieni po rozpoczęciu porady.
        </div>
      </section>
      <section className="container price-tag-section">
        <div className="price-wrapper">
          <div className="price-container-header">
            <div>Porada Dermatologiczna Online</div>
            <div className="cena-text">200 zł</div>
          </div>
          <div className="price-benefits-list">
            {perks.map(perk => {
                if (perk === "odpowiedz w 24h") {
                  return (
                    <div className="perk-container">
                      <div className="perk-text">
                        odpowiedź w <strong>24h</strong>
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div className="perk-container">
                      <div className="perk-text">
                        <strong>{perk}</strong>
                      </div>
                    </div>
                  )

                }
              },
            )}
          </div>
          <div className="button-kup">
              <a href={"https://online-dermatolog.pl/patient-form"} style={{ backgroundColor: "#91487 !important"}} className="button">Rozpocznij Poradę Online
              </a>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  )
}
export default KonsultacjaDermatologicznaOnline
